import React, { useState, useEffect, useCallback, useMemo } from "react";
import SideNav from "../../../../components/header/SideNav";
import TopNav from "../../../../components/header/TopNav";
import { useNavigate, useParams } from "react-router-dom";
import data from "./sub.json";

const PastQuestions = () => {
  const { subject, section } = useParams(); // Get subject and section from URL params
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const storedIndex = localStorage.getItem("currentQuestionIndex");
    return storedIndex ? parseInt(storedIndex) : 0;
  });
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const storedSelectedOptions = localStorage.getItem("selectedOptions");
    return storedSelectedOptions ? JSON.parse(storedSelectedOptions) : {};
  });
  const [remainingTime, setRemainingTime] = useState(() => {
    const storedRemainingTime = localStorage.getItem("remainingTime");
    return storedRemainingTime ? parseInt(storedRemainingTime) : 60;
  });
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(() => {
    const storedScore = localStorage.getItem("score");
    return storedScore ? parseInt(storedScore) : 0;
  });

  // Get questions for the specific subject and section
  const getFilteredQuestions = () => {
    const subjectData = data.find((item) => item[subject]);
    if (subjectData) {
      console.log(`Subject Data:`, subjectData);
      return subjectData[subject][section] || [];
    }
    console.log(
      `No data found for subject: ${subject} and section: ${section}`
    );
    return [];
  };

  const filteredQuestions = getFilteredQuestions();
  const getTotalQuestionCount = useMemo(() => {
    return filteredQuestions.length;
  }, [filteredQuestions]);

  const handleNextQuestion = useCallback(() => {
    setShowResult(false);
    if (currentQuestionIndex < getTotalQuestionCount - 1) {
      const newIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newIndex);
      setRemainingTime(60);
      localStorage.setItem("currentQuestionIndex", newIndex);
      localStorage.setItem("remainingTime", 60);
      localStorage.setItem("startTime", Date.now());
    } else {
      localStorage.removeItem("currentQuestionIndex");
      navigate("/result");
    }
  }, [currentQuestionIndex, getTotalQuestionCount, navigate]);

  useEffect(() => {
    const startTime = localStorage.getItem("startTime");
    if (!startTime) {
      localStorage.setItem("startTime", Date.now());
    }

    const updateTimer = () => {
      const startTime = parseInt(localStorage.getItem("startTime"));
      const elapsed = Math.floor((Date.now() - startTime) / 1000);
      const timeLeft = 60 - elapsed;
      if (timeLeft > 0) {
        setRemainingTime(timeLeft);
        localStorage.setItem("remainingTime", timeLeft);
      } else {
        setRemainingTime(0);
        clearInterval(timer);
        setShowResult(true);
        setTimeout(() => {
          handleNextQuestion();
        }, 2000);
      }
    };

    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, [currentQuestionIndex, handleNextQuestion]);

  const handleOptionChange = (event) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [currentQuestion.id]: event.target.value,
    }));
  };

  const handleNextButtonClick = () => {
    if (selectedOptions[currentQuestion.id] === currentQuestion.answer) {
      setScore((prevScore) => {
        const newScore = prevScore + 1;
        localStorage.setItem("score", newScore);
        return newScore;
      });
    }
    setShowResult(true);
    setTimeout(() => {
      handleNextQuestion();
    }, 2000);
  };

  useEffect(() => {
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  useEffect(() => {
    if (showResult && currentQuestionIndex === getTotalQuestionCount - 1) {
      localStorage.setItem("finalScore", score);
      localStorage.setItem("totalQuestions", getTotalQuestionCount);
    }
  }, [showResult, currentQuestionIndex, score, getTotalQuestionCount]);

  const getCurrentQuestion = () => {
    return filteredQuestions[currentQuestionIndex] || null;
  };

  const currentQuestion = getCurrentQuestion();

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="home_section">
      <div className={show ? "show" : "hidden"}>
        <SideNav />
        <div className="overlay" onClick={handleShow}></div>
      </div>
      <div className="right">
        <TopNav handleShow={handleShow} show={show} />
        <div className="home_container p-3 p-lg-4">
          <h6>Subject: {subject}</h6>
          <h6 className="italic">Section: {section}</h6>
          <h6>Remaining Time: {remainingTime}</h6>
          {currentQuestion ? (
            <div className="question_block mt-4">
              <h4 className="text-dark">
                {currentQuestionIndex + 1}. {currentQuestion.question}
              </h4>
              <div className="options_container">
                {currentQuestion.options.map((option, index) => {
                  const isCorrect = option === currentQuestion.answer;
                  const isSelected =
                    option === selectedOptions[currentQuestion.id];
                  const optionClass =
                    showResult &&
                    (isCorrect ? "correct" : isSelected ? "incorrect" : "");

                  return (
                    <div key={index} className={`option_item ${optionClass}`}>
                      <input
                        type="radio"
                        id={`q${currentQuestion.id}_option${index}`}
                        name={`question${currentQuestion.id}`}
                        value={option}
                        onChange={handleOptionChange}
                        checked={selectedOptions[currentQuestion.id] === option}
                        disabled={showResult}
                      />
                      &nbsp;
                      <label htmlFor={`q${currentQuestion.id}_option${index}`}>
                        {String.fromCharCode(65 + index)}. {option}
                      </label>
                    </div>
                  );
                })}
              </div>
              <button
                className="next_button"
                onClick={handleNextButtonClick}
                disabled={!selectedOptions[currentQuestion.id]}
              >
                Next
              </button>
            </div>
          ) : (
            <p>No questions available for this subject and section.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PastQuestions;
