import axios from "axios";
const API = axios.create({
  baseURL: "https://learninghammer.hammerlabcreations.com/api",
});

// API.interceptors.request.use((req) => {
//   if (localStorage.getItem("profile")) {
//     req.headers.Authorization = `Bearer ${
//       JSON.parse(localStorage.getItem("profile")).token
//     }`;
//   }
//   return req;
// });

// auth
export const login = (formData) => API.post("/login", formData);
export const register = (formData) => API.post("/register", formData);
// subjects
export const lavels = () => API.get("/levels");
export const subjects = ({ slug }) => API.get(`/subjects/${slug}`);
export const items = (sub_slug, cat_slug) =>
  API.get(`/subject/${sub_slug}/category/${cat_slug}`);

// entrance

export const entranceSubjects = () => API.get("/entrance/subjects");
export const entranceSubjectsName = (sub_slug, sub_type) =>
  API.get(`/entrance/subjects/${sub_slug}/${sub_type}`);

export const subjectUnits = (id) => API.get(`/entrance/subjects/${id}/mcq`);
export const questioins = (id, uid, utype) =>
  API.get(`/entrance/subjects/${id}/mcq/${uid}/${utype}`);
