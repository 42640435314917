import { AiFillHome } from "react-icons/ai";
// import { GiThink } from "react-icons/gi";
import { Link } from "react-router-dom";

const SideNav = () => {
  return (
    <nav className="side_bar ps-4 pt-4 pe-4">
      <div className="logo pb-3 mb-3">
        <h2 className="pb-3">Learning Hammer</h2>
        <Link
          to="/"
          className="link side_link"
          style={{ display: "flex", alignItems: "center" }}
        >
          <AiFillHome className="hicon" />
          &nbsp; Home
        </Link>

        {/* <Link
          to="/mcq"
          className="link side_link"
          style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}
        >
          <GiThink className="hicon" />
          &nbsp; Entrance Preparation
        </Link> */}
      </div>

      <div>
        <h5>School</h5>
        <ul className="sub_category_list">
          <li>
            <Link to="/school/grade-x" className="no-underline text-white">
              Grade X
            </Link>
          </li>
        </ul>
        <h5>NEB</h5>
        <ul className="sub_category_list">
          <li>
            <Link to="/school/grade-xii" className="no-underline text-white">
              Grade XII
            </Link>
          </li>
        </ul>
        <h5>Bachelor</h5>
        <ul className="sub_category_list">
          <li>
            <Link to="/school/bsccsit" className="no-underline text-white">
              BSc.CSIT
            </Link>
          </li>
        </ul>
        <h5>Exam Preparation</h5>
        <ul className="sub_category_list">
          <li>
            <Link
              to="/plus-2-science/entrance-preparation"
              className="no-underline text-white"
            >
              Bridge Course (Science)
            </Link>
          </li>
          <li>
            <Link
              to="/plus-2-management/entrance-preparation"
              className="no-underline text-white"
            >
              Bridge Course (Management)
            </Link>
          </li>
          <li>
            <Link
              to="/csit/entrance-preparation"
              className="no-underline text-white"
            >
              B.Sc.CSIT Entrance
            </Link>
          </li>
          <li>
            <Link to="/bca" className="no-underline text-white">
              BCA Entrance
            </Link>
          </li>
        </ul>
        <h5>Online Examination</h5>
        <ul className="sub_category_list">
          <li>
            <Link to="/exam/science" className="no-underline text-white">
              +2 Science
            </Link>
          </li>
          <li>
            <Link to="/exam/management" className="no-underline text-white">
              +2 Management
            </Link>
          </li>
          <li>
            <Link to="/" className="no-underline text-white">
              B.Sc.CSIT Entrance
            </Link>
          </li>
          <li>
            <Link to="/" className="no-underline text-white">
              BCA Entrance
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SideNav;
