import { useState } from "react";
import SideNav from "../../../components/header/SideNav";
import TopNav from "../../../components/header/TopNav";
// import { useNavigate } from "react-router-dom";

const BcaHome = () => {
  const [show, setShow] = useState(true);
  // const navigate = useNavigate();
  const handleShow = () => {
    setShow(!show);
  };
  // const handleClick = () => {
  //   localStorage.clear();
  //   navigate("/exam-questions");
  // };
  return (
    <div className="home_section">
      <div className={show ? "show" : "hidden"}>
        <SideNav />
        <div className="overlay" onClick={handleShow}></div>
      </div>
      <div className="right">
        <TopNav handleShow={handleShow} show={show} />
        <div className="home_container p-3 p-lg-4">
          <div className="pt-3 pt-lg-5">
            <h2>Be Ready for BCA Entrance Preparation</h2> <br />
            <p>
              "Apply for scholarships with New Summit College's online exams.
              Showcase your skills and secure your <br /> future with our
              streamlined, merit-based selection process."
            </p>
            <div className="exam_form mt-5">
              <button onClick={() => alert("Comming Soon!")}>Start Test</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BcaHome;
