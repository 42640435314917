import { useState, useEffect } from "react";
import SideNav from "../../components/header/SideNav";
import TopNav from "../../components/header/TopNav";
import TypeWriter from "./TypeWriter";

const Result = () => {
  const [show, setShow] = useState(true);
  const [finalScore, setFinalScore] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);

  useEffect(() => {
    const storedScore = localStorage.getItem("finalScore");
    const storedTotalQuestions = localStorage.getItem("totalQuestions");

    if (storedScore) {
      setFinalScore(parseInt(storedScore));
    }
    if (storedTotalQuestions) {
      setTotalQuestions(parseInt(storedTotalQuestions));
    }
  }, []);
  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="home_section">
      <div className={show ? "show" : "hidden"}>
        <SideNav />
        <div className="overlay" onClick={handleShow}></div>
      </div>
      <div className="right">
        <TopNav handleShow={handleShow} show={show} />
        <div className="home_container p-3 p-lg-4">
          <div className="main congra">
            <div className="video-background">
              <video autoPlay loop muted>
                <source src="./cog.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="result_overlay ">
                <TypeWriter text="Congratulations!" />
                <h3>
                  You got : &nbsp; {finalScore} / {totalQuestions}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
