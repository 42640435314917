import React, { useState, useEffect, useCallback, useMemo } from "react";
import SideNav from "../../components/header/SideNav";
import TopNav from "../../components/header/TopNav";
import { useNavigate } from "react-router-dom";
import data from "../../constant/data.json";

const ExamQuestions = () => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const [categoryQuestions, setCategoryQuestions] = useState(() => {
    const storedQuestions = localStorage.getItem("categoryQuestions");
    return storedQuestions ? JSON.parse(storedQuestions) : {};
  });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const storedIndex = localStorage.getItem("currentQuestionIndex");
    return storedIndex ? parseInt(storedIndex) : 0;
  });

  const [selectedOptions, setSelectedOptions] = useState(() => {
    const storedSelectedOptions = localStorage.getItem("selectedOptions");
    return storedSelectedOptions ? JSON.parse(storedSelectedOptions) : {};
  });

  const [remainingTime, setRemainingTime] = useState(30);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(() => {
    const storedScore = localStorage.getItem("score");
    return storedScore ? parseInt(storedScore) : 0;
  });

  useEffect(() => {
    const groupQuestionsByCategory = () => {
      if (Object.keys(categoryQuestions).length === 0) {
        const groupedCategories = {};
        const categories = [
          "english",
          "physics",
          "chemistry",
          "biology",
          "mathematics",
          "gk",
        ];

        categories.forEach((category) => {
          const categoryQuestions = data.filter(
            (question) => question.category === category
          );
          const randomSubset = categoryQuestions
            .sort(() => 0.5 - Math.random())
            .slice(0, getCategoryLimit(category));
          groupedCategories[category] = randomSubset;
        });

        setCategoryQuestions(groupedCategories);
        localStorage.setItem(
          "categoryQuestions",
          JSON.stringify(groupedCategories)
        );
      }
    };

    const getCategoryLimit = (category) => {
      switch (category) {
        case "english":
          return 20;
        case "physics":
          return 20;
        case "chemistry":
          return 20;
        case "biology":
          return 10;
        case "mathematics":
          return 20;
        case "gk":
          return 10;
        default:
          return 0;
      }
    };

    groupQuestionsByCategory();
  }, [categoryQuestions]);

  const getTotalQuestionCount = useMemo(() => {
    return Object.values(categoryQuestions).reduce(
      (acc, categoryQuestions) => acc + categoryQuestions.length,
      0
    );
  }, [categoryQuestions]);

  const handleNextQuestion = useCallback(() => {
    setShowResult(false);
    if (currentQuestionIndex < getTotalQuestionCount - 1) {
      const newIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newIndex);
      setRemainingTime(30);
      localStorage.setItem("currentQuestionIndex", newIndex);
    } else {
      localStorage.removeItem("currentQuestionIndex");
      navigate("/result");
    }
  }, [currentQuestionIndex, getTotalQuestionCount, navigate]);

  useEffect(() => {
    const startTime = Date.now();
    const timer = setInterval(() => {
      const elapsed = Math.floor((Date.now() - startTime) / 1000);
      const timeLeft = 30 - elapsed;
      if (timeLeft > 0) {
        setRemainingTime(timeLeft);
      } else {
        clearInterval(timer);
        setShowResult(true);
        setTimeout(() => {
          handleNextQuestion();
        }, 2000);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [currentQuestionIndex, handleNextQuestion]);

  const handleOptionChange = (event) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [currentQuestion.id]: event.target.value,
    }));
  };

  const handleNextButtonClick = () => {
    if (selectedOptions[currentQuestion.id] === currentQuestion.answer) {
      setScore((prevScore) => {
        const newScore = prevScore + 1;
        localStorage.setItem("score", newScore);
        return newScore;
      });
    }
    setShowResult(true);
    setTimeout(() => {
      handleNextQuestion();
    }, 2000);
  };

  useEffect(() => {
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  useEffect(() => {
    if (showResult && currentQuestionIndex === getTotalQuestionCount - 1) {
      localStorage.setItem("finalScore", score);
      localStorage.setItem("totalQuestions", getTotalQuestionCount);
    }
  }, [showResult, currentQuestionIndex, score, getTotalQuestionCount]);

  const getCurrentQuestion = () => {
    let questionCount = 0;
    for (const category in categoryQuestions) {
      for (const question of categoryQuestions[category]) {
        if (questionCount === currentQuestionIndex) {
          return question;
        }
        questionCount++;
      }
    }
    return null;
  };

  const currentQuestion = getCurrentQuestion();

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="home_section">
      <div className={show ? "show" : "hidden"}>
        <SideNav />
        <div className="overlay" onClick={handleShow}></div>
      </div>
      <div className="right">
        <TopNav handleShow={handleShow} show={show} />
        <div className="home_container p-3 p-lg-4">
          <h3>Remaining Time: {remainingTime}</h3>
          {currentQuestion && (
            <div className="question_block mt-4">
              <h4 className="text-dark">
                {currentQuestionIndex + 1}. {currentQuestion.question}
              </h4>
              <div className="options_container">
                {currentQuestion.options.map((option, index) => {
                  const isCorrect = option === currentQuestion.answer;
                  const isSelected =
                    option === selectedOptions[currentQuestion.id];
                  const optionClass =
                    showResult &&
                    (isCorrect ? "correct" : isSelected ? "incorrect" : "");

                  return (
                    <div key={index} className={`option_item ${optionClass}`}>
                      <input
                        type="radio"
                        id={`q${currentQuestion.id}_option${index}`}
                        name={`question${currentQuestion.id}`}
                        value={option}
                        onChange={handleOptionChange}
                        checked={selectedOptions[currentQuestion.id] === option}
                        disabled={showResult}
                      />
                      &nbsp;
                      <label htmlFor={`q${currentQuestion.id}_option${index}`}>
                        {String.fromCharCode(65 + index)}. {option}
                      </label>
                    </div>
                  );
                })}
              </div>
              <button
                className="next_button"
                onClick={handleNextButtonClick}
                disabled={!selectedOptions[currentQuestion.id]}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExamQuestions;
