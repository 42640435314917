import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";
import sub from "./sub.json";

const Subject = ({ subject, data, handleClick }) => (
  <div className="subject_card">
    <h5 className="mb-8">{subject}</h5>
    {Object.keys(data).map((section, i) => (
      <p className="-mt-2  bg-gray-300 p-2" key={i}>
        <Link
          to={`/entrance-preparation/${subject}/${section}`}
          onClick={handleClick}
          className="flex justify-between text-dark underline-none no-underline"
        >
          {section} <FaPlay size={12} className="mt-2" />
        </Link>
      </p>
    ))}
  </div>
);

const CsitSubject = () => {
  const handleClick = () => {
    // Store the profile data
    const profile = localStorage.getItem("profile");
    // Clear all localStorage
    localStorage.clear();
    // Restore the profile data
    if (profile !== null) {
      localStorage.setItem("profile", profile);
    }
  };
  return (
    <div>
      <h2 className="pt-5 mt-2">Let's Practice Subject wise Questions !!</h2>
      <div className="years mt-4">
        <div className="course_group pt-1 pt-lg-2">
          <div className="course_card">
            {sub.map((subjectData, index) => {
              const subject = Object.keys(subjectData)[0];
              return (
                <Subject
                  key={index}
                  subject={subject}
                  data={subjectData[subject]}
                  handleClick={handleClick}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CsitSubject;
