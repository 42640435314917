import { configureStore } from "@reduxjs/toolkit";
import LevelReducer from "./features/levelSlice";
import EntranceReducer from "./features/entranceSlice";
import AuthReducer from "./features/authSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    level: LevelReducer,
    entrance: EntranceReducer,
  },
});
