import { useState, useEffect, useCallback } from "react";
import SideNav from "../../../components/header/SideNav";
import TopNav from "../../../components/header/TopNav";
import data from "./data";
import { useNavigate } from "react-router-dom";

const ManagementExamQuestion = () => {
  const [show, setShow] = useState(true);
  const handleShow = () => {
    setShow(!show);
  };

  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const storedIndex = localStorage.getItem("currentQuestionIndex");
    return storedIndex ? parseInt(storedIndex) : 0;
  });
  const [remainingTime, setRemainingTime] = useState(60);
  const [selectedOption, setSelectedOption] = useState(null);

  const randomizeQuestionSet = useCallback(() => {
    const storedSetIndex = localStorage.getItem("setIndex");
    if (storedSetIndex) {
      return parseInt(storedSetIndex);
    } else {
      const newSetIndex = Math.floor(Math.random() * data.length);
      localStorage.setItem("setIndex", newSetIndex);
      return newSetIndex;
    }
  }, []);

  const [setIndex] = useState(() => randomizeQuestionSet());
  const [questionSet] = useState(() => data[setIndex]);

  const handleNextQuestion = useCallback(() => {
    if (currentQuestionIndex < questionSet.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setRemainingTime(60);
      setSelectedOption(null);
      localStorage.setItem("startTime", Date.now());
      localStorage.setItem("currentQuestionIndex", currentQuestionIndex + 1);
    } else {
      navigate("/thankyou");
    }
  }, [currentQuestionIndex, questionSet.questions.length, navigate]);

  useEffect(() => {
    const startTime = localStorage.getItem("startTime");
    const elapsedTime = startTime
      ? Math.floor((Date.now() - parseInt(startTime)) / 1000)
      : 0;
    const initialRemainingTime = 60 - elapsedTime;

    if (initialRemainingTime > 0) {
      setRemainingTime(initialRemainingTime);
    } else {
      handleNextQuestion();
    }

    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 1) {
          return prevTime - 1;
        } else {
          handleNextQuestion();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [handleNextQuestion]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const currentQuestion = questionSet.questions[currentQuestionIndex];
  return (
    <div className="home_section">
      <div className={show ? "show" : "hidden"}>
        <SideNav />
        <div className="overlay" onClick={handleShow}></div>
      </div>
      <div className="right">
        <TopNav handleShow={handleShow} show={show} />
        <div className="home_container p-3 p-lg-4">
          <div className="questions">
            <div className="question">
              <div className="">
                <h3>Set Number: {setIndex + 1}</h3>
                <h5 className="text-2xl mb-3">
                  Attempt all the questions and darken your answer in the given
                  answer sheet.
                </h5>
                <h3>Remaining Time: {remainingTime}s</h3>
              </div>
              <div className="mt-5">
                <h4 className="text-dark mb-4 text-bold">
                  {currentQuestionIndex + 1}. {currentQuestion.question}
                </h4>
                <div className="options_container">
                  {currentQuestion.options.map((option, index) => (
                    <div key={index} className="option_item">
                      <input
                        type="radio"
                        id={`q${currentQuestionIndex}_option${index}`}
                        name={`question${currentQuestionIndex}`}
                        value={option}
                        checked={selectedOption === option}
                        onChange={() => handleOptionChange(option)}
                      />
                      &nbsp;
                      <label
                        htmlFor={`q${currentQuestionIndex}_option${index}`}
                        className="text-bold"
                      >
                        {String.fromCharCode(65 + index)}. {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <button onClick={handleNextQuestion} className="next_button">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementExamQuestion;
