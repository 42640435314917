import { Suspense, lazy, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loading from "./components/loading/Loading";
import ExamHome from "./pages/exam/ExamHome";
import Result from "./pages/exam/Result";
import CsitHome from "./pages/exam/csit/CsitHome";
import BcaHome from "./pages/exam/bca/BcaHome";
import MgmtHome from "./pages/exam/MgmtHome";
import ScienceExamHome from "./pages/exam/science/ScienceExamHome";
import ScienceExamQuestion from "./pages/exam/science/SienceExamQuestion";
import ExamQuestions from "./pages/exam/ExamQuestions";
import Thankyou from "./components/common/Thankyou";
import ManagementExamHome from "./pages/exam/management/MnagementExamHome";
import ManagementExamQuestion from "./pages/exam/management/ManagementExamQuestion";
import CsitPreparationHome from "./pages/exam-preparation/csit/CsitPreparationHome";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/features/authSlice";
import CsitPrepQuestions from "./pages/exam-preparation/csit/CsitPrepQuestions";
import PastQuestions from "./pages/exam-preparation/csit/past_csit/PastQuestions";
import CsitSubjectQuestions from "./pages/exam-preparation/csit/subject/CsitSubjectQuestions";
import PrivateRoute from "./routes/Private";
import ObjectiveQuestions from "./components/common/ObjectiveQuestions";

const Home = lazy(() => import("./pages/Home"));
const Objectives = lazy(() => import("./components/common/Objectives"));
const SubjectUnit = lazy(() => import("./components/common/SubjectUnit"));
// const McqsubDetails = lazy(() => import("./pages/McqSubDetails"));
const Subject = lazy(() => import("./pages/Subject"));
const SubjectDetails = lazy(() => import("./pages/SubjectDetails"));
// const McqSubject = lazy(() => import("./pages/McqSubjects"));
// const McqSubUnit = lazy(() => import("./pages/McqSubUnit"));
// const Mcq = lazy(() => import("./components/common/Mcq"));
const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));

const App = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    dispatch(setUser(user));
  }, [dispatch, user]);
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* <Route path="/mcq" element={<McqSubject />} />
          <Route path="/mcq/:id" element={<McqSubUnit />} />
          <Route path="/mcq/:id/:type" element={<Mcq />} /> */}
          <Route path="/school/:slug" element={<Subject />} />
          <Route path="/school/:slug/:type" element={<SubjectDetails />} />
          {/* mcq subject */}
          <Route
            path="/school/:slug/:type/:id"
            element={<ObjectiveQuestions />}
          />
          {/* entrance */}
          {/* <Route path="/entrance/:slug/:type" element={<McqsubDetails />} /> */}
          <Route path="/mcq/subject/:title/:id" element={<SubjectUnit />} />
          <Route
            path="/mcq/subject/:title/:id/:utitle/:uid/:utype"
            element={<Objectives />}
          />
          {/* exam preparation */}
          <Route
            path="/csit/entrance-preparation"
            element={<CsitPreparationHome />}
          />
          {/* <Route path="" element={<PrivateRoute />}> */}
          {/* csit */}

          <Route
            path="/csit/entrance-questions"
            element={<CsitPrepQuestions />}
          />
          <Route
            path="/entrance-preparation/:subject/:section"
            element={<CsitSubjectQuestions />}
          />
          <Route path="/csit-entrance/:year" element={<PastQuestions />} />
          {/* </Route> */}

          {/* exam */}
          <Route
            path="/plus-2-science/entrance-preparation"
            element={<ExamHome />}
          />
          <Route
            path="/plus-2-management/entrance-preparation"
            element={<MgmtHome />}
          />
          <Route path="/csit" element={<CsitHome />} />
          <Route path="/bca" element={<BcaHome />} />
          {/*  */}
          <Route path="/exam-questions" element={<ExamQuestions />} />
          <Route path="/result" element={<Result />} />
          {/* science */}
          <Route path="/exam/science" element={<ScienceExamHome />} />
          <Route path="/exam/science/start" element={<ScienceExamQuestion />} />
          {/* management */}
          <Route path="/exam/management" element={<ManagementExamHome />} />
          <Route
            path="/exam/management/start"
            element={<ManagementExamQuestion />}
          />
          <Route path="/thankyou" element={<Thankyou />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
