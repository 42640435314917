import React, { useCallback, useEffect, useState } from "react";

const Quiz = ({ questions }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [score, setScore] = useState(0);
  const [quizStarted, setQuizStarted] = useState(true);
  const [timer, setTimer] = useState(30);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // New state to track if the answer is correct

  useEffect(() => {
    let interval;
    if (quizStarted && currentQuestion < questions.length) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [quizStarted, currentQuestion, questions.length]);

  const handleStartQuiz = () => {
    setQuizStarted(true);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsAnswerCorrect(null); // Reset the answer check when a new option is selected
  };

  const handleNextQuestion = useCallback(() => {
    if (selectedOption) {
      const isCorrect = selectedOption === questions[currentQuestion]?.answer;
      setIsAnswerCorrect(isCorrect); // Set the correctness flag

      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
      setTimeout(() => {
        setSelectedOption("");
        setIsAnswerCorrect(null);
        setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
        setTimer(30); // Reset timer for the next question
      }, 1000); // Delay of 1 second to show the result before moving to the next question
    }
    // setScore((prevScore) => prevScore + 1);
    // Delay moving to the next question to give time to show feedback
  }, [currentQuestion, questions, selectedOption]);

  useEffect(() => {
    if (timer === 0) {
      handleNextQuestion();
    }
  }, [timer, handleNextQuestion]);

  return (
    <div className="quiz_container">
      {questions && questions.length > 0 ? (
        <>
          {!quizStarted ? (
            <button onClick={handleStartQuiz}>Start Quiz</button>
          ) : (
            <div>
              {currentQuestion < questions.length ? (
                <>
                  <h2>
                    {currentQuestion + 1}.{questions[currentQuestion].question}
                  </h2>
                  <p>Time Left: {timer} seconds</p>
                  <ul>
                    {questions[currentQuestion].options.map((option, index) => {
                      const isSelected = selectedOption === option;
                      const isCorrect =
                        option === questions[currentQuestion]?.answer;

                      // Conditional class for background color
                      let backgroundColor = "";
                      if (isAnswerCorrect !== null) {
                        if (isSelected && !isCorrect)
                          backgroundColor = "bg-red-500";
                        if (isCorrect) backgroundColor = "bg-green-500";
                      }

                      return (
                        <li
                          key={index}
                          className={`p-2 my-2 ${backgroundColor}`} // Apply the background color here
                        >
                          <input
                            type="radio"
                            id={option}
                            name="option"
                            value={option}
                            checked={isSelected}
                            disabled={isAnswerCorrect !== null} // Disable options once the answer is checked
                            onChange={() => handleOptionSelect(option)}
                          />
                          <label htmlFor={option}>&nbsp;{option}</label>
                        </li>
                      );
                    })}
                  </ul>
                  {isAnswerCorrect === null && (
                    <button onClick={handleNextQuestion}>Next</button>
                  )}
                </>
              ) : (
                <div>
                  <h2>Quiz Completed!</h2>
                  <p className="font-bold">
                    Your score: {score} out of {questions.length}
                  </p>
                  <button
                    onClick={() => {
                      setCurrentQuestion(0);
                      setSelectedOption("");
                      setScore(0);
                      setTimer(30);
                      setQuizStarted(true);
                    }}
                  >
                    Play Again
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <p>No questions available!</p>
      )}
    </div>
  );
};

export default Quiz;
