import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItems } from "../../redux/features/levelSlice";
import { useParams } from "react-router-dom";
import SideNav from "../header/SideNav";
import TopNav from "../header/TopNav";
import Quiz from "./Quiz";

const ObjectiveQuestions = () => {
  const [show, setShow] = useState(true);
  const handleShow = () => {
    setShow(!show);
  };
  const { items } = useSelector((state) => state.level);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    if (params.slug && params.type) {
      dispatch(getItems({ sub_slug: params.slug, cat_slug: params.type }));
    }
  }, [dispatch, params.slug, params.type]);

  return (
    <div className="home_section">
      <div className={show ? "show" : "hidden"}>
        <SideNav />
        <div className="overlay" onClick={handleShow}></div>
      </div>
      <div className="right">
        <TopNav handleShow={handleShow} show={show} />
        <div className="home_container p-3 p-lg-4">
          {items &&
            items.map((i, index) => (
              <div key={index} style={{ position: "relative" }}>
                {i.content &&
                  i?.content?.length > 0 &&
                  i.content?.map((q, i) => {
                    if (String(q.id) === params.id) {
                      return (
                        <div key={i}>
                          <h3 className="ps-2">Unit: {q.name}</h3>
                          <Quiz questions={JSON.parse(q.json_data)} />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ObjectiveQuestions;
