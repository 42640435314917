import axios from "axios";
import { PiSignIn } from "react-icons/pi";
import { BiLogOut } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../redux/features/authSlice";

const TopNav = ({ handleShow, show }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const handleLogout = async () => {
    try {
      const response = await axios.post(
        "https://learninghammer.hammerlabcreations.com/api/logout"
      );
      if (response) {
        toast.success(response?.data?.message);
        dispatch(setLogout());
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="top_bar">
      <div className="top_head">
        <div className="icon">
          <FiMenu onClick={handleShow} className="menu" />
        </div>
        <h3>Learning Hammer</h3>
      </div>
      <div className="auth">
        {user?.token ? (
          <span className="auth cursor-pointer">
            <BiLogOut className="auth_icon" onClick={() => handleLogout()} />
          </span>
        ) : (
          <Link to="/login" className="auth">
            <PiSignIn className="auth_icon" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default TopNav;
