import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const getEntranceSubjects = createAsyncThunk(
  "entrance/getEntranceSubjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.entranceSubjects();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEntranceSubjectsName = createAsyncThunk(
  "entrance/getEntranceSubjectsName",
  async ({ sub_slug, sub_type }, { rejectWithValue }) => {
    try {
      const response = await api.entranceSubjectsName(sub_slug, sub_type);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSubjectUnits = createAsyncThunk(
  "entrance/getSubjectUnits",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await api.subjectUnits(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getQuestions = createAsyncThunk(
  "entrance/getQuestions",
  async ({ id, uid, utype }, { rejectWithValue }) => {
    try {
      const response = await api.questioins(id, uid, utype);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const entranceSlice = createSlice({
  name: "entrance",
  initialState: {
    entranceSubjects: [],
    entranceSubjectNames: [],
    subjectUnits: [],
    questions: [],
    error: "",
    loading: false,
  },
  reducers: {
    resetError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntranceSubjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntranceSubjects.fulfilled, (state, action) => {
        state.loading = false;
        state.entranceSubjects = action.payload;
      })
      .addCase(getEntranceSubjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
      .addCase(getEntranceSubjectsName.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntranceSubjectsName.fulfilled, (state, action) => {
        state.loading = false;
        state.entranceSubjectNames = action.payload;
      })
      .addCase(getEntranceSubjectsName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
      .addCase(getSubjectUnits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubjectUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.subjectUnits = action.payload;
      })
      .addCase(getSubjectUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
      .addCase(getQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      });
  },
});

export const { resetError } = entranceSlice.actions;

export default entranceSlice.reducer;
