import { useState } from "react";
import SideNav from "../../../components/header/SideNav";
import TopNav from "../../../components/header/TopNav";
import { useNavigate } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";
import CsitSubject from "./subject/CsitSubject";

const CsitPreparationHome = () => {
  const years = [2074, 2075, 2076, 2077, 2078, 2079];

  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const handleShow = () => {
    setShow(!show);
  };
  const handleClick = () => {
    // Store the profile data
    const profile = localStorage.getItem("profile");
    // Clear all localStorage
    localStorage.clear();
    // Restore the profile data
    if (profile !== null) {
      localStorage.setItem("profile", profile);
    }
    navigate("/csit/entrance-questions");
  };
  const handleClickOne = () => {
    // Store the profile data
    const profile = localStorage.getItem("profile");
    // Clear all localStorage
    localStorage.clear();
    // Restore the profile data
    if (profile !== null) {
      localStorage.setItem("profile", profile);
    }
  };
  return (
    <div className="home_section">
      <div className={show ? "show" : "hidden"}>
        <SideNav />
        <div className="overlay" onClick={handleShow}></div>
      </div>
      <div className="right">
        <TopNav handleShow={handleShow} show={show} />
        <div className="home_container p-3 p-lg-4">
          <div className="pt-3 pt-lg-5">
            <h2>Be Ready for B.Sc.CSIT Entrance Preparation</h2> <br />
            <p>
              "Apply for scholarships with New Summit College's online exams.
              Showcase your skills and secure your <br /> future with our
              streamlined, merit-based selection process."
            </p>
            <div className="exam_form mt-5">
              <button onClick={() => handleClick()}>Start Now</button>
            </div>
          </div>
          <div>
            <h2 className="pt-5 mt-2">Let's Practice Past Questions !!</h2>
            <div className="years">
              <div className="course_group pt-1 pt-lg-2">
                <div className="course_card">
                  {years &&
                    years.map((s, i) => (
                      <div
                        className="subject_card"
                        key={i}
                        onClick={() => handleClickOne()}
                      >
                        <Link to={`/csit-entrance/${s}`} className="card_link">
                          <div className="title">{s} Entrance Questions</div>
                          <div className="icon">
                            <FaPlay className="mt-1.5" />
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <CsitSubject />
        </div>
      </div>
    </div>
  );
};

export default CsitPreparationHome;
